<template>
    <div>
     
       <div class="newsinfo">
			
			<div class="container">
                <p class="topp">
                    <a style="color:#333;cursor: pointer;" @click="tourl">首页</a>
                    <img src="../../assets/right.png" alt="">	
                    <span style="color:#333">动态</span>
                </p>
				<h1>{{ info.title }}</h1>
                <p class="tip"><span>来源：{{ info.source }}</span> <span>时间：{{ info.create_time }}</span></p>
				 <div class="txt">
                    <div v-html="description"></div>
                 </div>
			</div>
           
		</div>
    </div>
    
</template>
    
<script>
import banner from '@/components/banner.vue'
import {getnewsinfoAPI} from "@/api/common.js"
export default {
   components: {
        banner
        
    },
    data () {
        return {
            total:0,
            pageSize: 10,
            pageNum: 1,
            info:{},
            id:"",
            description:""
        }
    },
    created () {
       
        console.log(this.$route.query.id)
        this.id=this.$route.query.id
        this.getlist()
    },
    methods: {
        getlist(){
            var data={
                id:this.id
            }
            getnewsinfoAPI(data).then(res=>{
                this.info=res
                this.description = res.txt
                this.description = res.txt.replace(/<video/gi,
							`<div class='videobox'><video `);
                this.description = this.description.replace(/<\/video>/gi,
							`</video></div> `); 
            })
        },
        tourl(){
            this.$router.push({ path: '/index' })
        }
    }
}
</script>
    
<style lang="scss">
.newsinfo{
    width: 100%;
    min-width: 1200px;        
    padding:30px 50px; 
   .topp{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    // a{
    //     width: 35px;
    //     text-align: center;
    // }
    a:hover{
        color: #00A1E8 !important;
         
    }
   h1{
    line-height: 80px;
   }
   .tip{
         margin-bottom: 20px;
        span{
            color: #999999;
            margin-right: 30px;
        }
   }
   .txt{
        min-height: 200px;
        line-height: 34px;
        .ql-align-center{
            text-align: center;
        }
        img{
            max-width: 100%;
        }
   }

}
.videobox{
    display: flex;
    justify-content: center;
}
.ql-video{
    width: 600px;
    margin: 10px auto;
     
}
</style>
    